/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyDurnsEc9aVnbBSePTwXLzF9svCTX6m1Hw",
  "appId": "1:1084713632855:web:e7674d0052f49871f20f12",
  "authDomain": "schooldog-i-lincoln-ga.firebaseapp.com",
  "measurementId": "G-WZQRQ7SV9V",
  "messagingSenderId": "1084713632855",
  "project": "schooldog-i-lincoln-ga",
  "projectId": "schooldog-i-lincoln-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-lincoln-ga.appspot.com"
}
